import './App.css';
import './config/firebase';
import React from 'react';
import { Helmet } from 'react-helmet';
import MainRouter from './routes/MainRouter';
import { AuthProvider } from './context/AuthContext';

const App = () => (
  <AuthProvider>
    <Helmet>
      <title>Jornal Joca - Biblioteca</title>
    </Helmet>
    <MainRouter />
  </AuthProvider>
);

export default App;
