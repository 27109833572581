import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ReactDOM from "react-dom";
import Search from '../assets/search.svg';
import BookAvatar from "./BookAvatar";
import AccessModal from "./AccessModal";

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  //flex: 1;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #fff;
  padding: 10px;
  @media screen and (max-width: 450px) {
    padding: 10px;
  }
`;

const Row = styled.div`
  display: flex;
  padding: 10px;
  justify-content: space-evenly;
  @media screen and (max-width: 450px) {
    flex-direction: column;
    align-items: center;
    gap: 15px
  }
`;

const Button = styled.button`
  display: flex;
  background: transparent;
  padding: 0;
  border: 0;
  font-family: "MontserratBold", sans-serif;
  font-size: 15px;
  align-items: center;
`;

const RedirectLink = styled(Link)`
  display: flex;
  background: transparent;
  padding: 0;
  border: 0;
  font-family: "MontserratBold", sans-serif;
  font-size: 15px;
  align-items: center;
  text-decoration: none;
  color: #848690;
`;

const BookCover = ({ cover, id, active, bookSummary, fullAccess }) => {
  // eslint-disable-next-line no-unused-vars
  const [modalVisible, setModalVisible] = useState(false);
  const modalPortalId = document.getElementById("book-preview");

  return (
    <Container>
      <Wrap>
        {
         modalPortalId && ReactDOM.createPortal(
          <AccessModal visible={modalVisible} closeRequest={() => setModalVisible(false)}/>,
          modalPortalId
          )
        }
        <BookAvatar cover={cover} active={!active} locked={active} height={485} />
        <Row>
          {!!bookSummary && (
            <RedirectLink to={() => {
              if (bookSummary) {
                return `/reader/${id}?preview=true`;
              }
              return '#'
            }} style={{ color: '#FF7B69' }}>
              <img width={17} src={Search} style={{ marginRight: 8 }} alt="" />
              Vizualizar amostra
            </RedirectLink>
          )}
          <RedirectLink
            style={{ color: active ? '#FF7B69' : '#848690' }}
            onClick={() => setModalVisible(!active)}
            to={() => {
            if (!active) {
              return '#';
            }
            return `/reader/${id}`;
          }}>
            <div style={{ marginRight: 8 }}>
              <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10 2.25278V15.2528M10 2.25278C8.83211 1.47686 7.24649 1 5.5 1C3.75351 1 2.16789 1.47686 1 2.25278V15.2528C2.16789 14.4769 3.75351 14 5.5 14C7.24649 14 8.83211 14.4769 10 15.2528M10 2.25278C11.1679 1.47686 12.7535 1 14.5 1C16.2465 1 17.8321 1.47686 19 2.25278V15.2528C17.8321 14.4769 16.2465 14 14.5 14C12.7535 14 11.1679 14.4769 10 15.2528"
                  stroke={active ? '#FF7B69' : '#848690'}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            Ler livro
          </RedirectLink>
        </Row>
      </Wrap>
    </Container>
  );
}

BookCover.propTypes = {
  cover: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  bookSummary: PropTypes.string,
};

BookCover.defaultProps = {
  bookSummary: null,
};

export default BookCover;