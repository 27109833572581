import React, { useEffect, useState } from 'react';
import { useWindowScroll } from 'react-use';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons';

const SideActions = styled.div`
  padding: 2rem;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  transition: 0.2s;
  opacity: 0.2;
  position: absolute;

  :hover {
    opacity: 1;
  }

  @media (max-width: 1200px) {
    opacity: 0;

    :hover {
      opacity: 0;
    }
  }
`;

const ScrollToTop = () => {
    const { y: pageYOffset } = useWindowScroll();
    const [ visible, setVisible ] = useState(false);

    useEffect(() => {
        if(pageYOffset > 400) {
            setVisible(true);
        }
        else {
            setVisible(false);
        }
    }, [pageYOffset])

    const scrollToTop = () => window.scrollTo( { top: 0, behavior: 'smooth' } ); 

    if(!visible) {
        return false;
    }

    return (
        <div>
            <SideActions style={{ left: 80 }} onClick={scrollToTop}>
                <FontAwesomeIcon color='#f05623' size='5x' icon={faChevronCircleLeft} rotation={270} />
            </SideActions>
        </div>
    );
}

export default ScrollToTop;