import React, { useEffect } from "react";
import styled from 'styled-components';
import SyncLoader from "react-spinners/SyncLoader";
import { useHistory, useParams } from "react-router-dom";
import { fbAuth } from "../config/firebase";

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Redirect = () => {
  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    fbAuth.signInWithCustomToken(token)
      .then(() => {
        setTimeout(() => history.replace('/'), 1000);
      })
      .catch(() => {
        setTimeout(() => history.replace('/login'), 1000);
      })
  }, [history, token]);

  return (
    <Container>
      <SyncLoader size={20} loading color='#f05623' />
    </Container>
  );
}

export default Redirect;
