import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";
import border from "../assets/Vector1.png";
import Lock from "../assets/Lock closed.svg";

const ImageContent = styled.div`
  position: relative;
  max-width: max-content;
  align-self: center;
`;

const LockIcon = styled.img`
  position: absolute;
  top: 8%;
  right: 10%;
  width: ${({ locked }) => locked ? 25 : 18}%;
`;

const Overlay = styled.div`
  position: absolute;
  background: #0005;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Image = styled.img`
  position: relative;
  object-fit: contain;
  border-radius: 8px;
  max-height: ${({ height }) => height}px;
  -webkit-filter: drop-shadow(-1px -2px 2px #0003);
  filter: drop-shadow(-1px -2px 2px #0003);
  @media screen and (max-width: 450px) {
    max-height: ${({ height }) => height * .8}px;
  }
`;

const FloatingDiv = styled.div`
  position: absolute;
  right: -1%;
  top: 0;
  background-color: transparent;
  background-size: contain;
  width: 35%;
  height: 30%;
  background-image: url(${border});
  background-repeat: no-repeat;
  z-index: 9;
`;

const BookAvatar = ({ active, height, cover }) => (
  <ImageContent>
    {active && <Overlay />}
    <Image height={height} src={cover} />
    {active && (
      <FloatingDiv>
        <LockIcon locked={active} src={Lock} />
      </FloatingDiv>
    )}
  </ImageContent>
);

BookAvatar.propTypes = {
  height: PropTypes.number,
  cover: PropTypes.string,
  active: PropTypes.bool,
};

BookAvatar.defaultProps = {
  height: 285,
  cover: '',
  active: false,
};

export default BookAvatar;