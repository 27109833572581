import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import DefaultInput from '../components/DefaultInput';
import DefaultBtn from '../components/DefaultBtn';
import LoginBox from '../components/LoginBox';
import { fbAuth } from '../config/firebase';
import { createAlert } from '../config/CreateAlert';
import DefaultTitle from '../components/DefaultTitle';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BottomLinks = styled(Link)`
  display: block;
  color: #333;
  font-weight: 600;
  text-align: center;
  margin-top: 8px;
  text-decoration: none;

  :hover {
    text-decoration: underline #f05623;
  }
`;

const RecoverPassword = () => {
  const [user, setUser] = useState('');
  const history = useHistory();

  return (
    <Container>
      <DefaultTitle>
        Recupere seu acesso a biblioteca do jornal <strong>Joca</strong>
      </DefaultTitle>
      <LoginBox>
        <DefaultInput
          value={user}
          label='Usuário'
          type='email'
          onChange={(event) => setUser(event.target.value)}
        />
        <DefaultBtn
          onClick={() =>
            fbAuth
              .sendPasswordResetEmail(user)
              .then(() => {
                createAlert('Recuperação enviada para seu e-mail');
                history.replace('/');
              })
              .catch((e) => {
                createAlert(e.message);
              })
          }
          label='Enviar E-Mail'
        />
        <BottomLinks to='/first-access'>Primeiro Acesso?</BottomLinks>
        <BottomLinks to='/login'>Já é registado ?</BottomLinks>
      </LoginBox>
    </Container>
  );
};

export default RecoverPassword;
