import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ASSETS_MAP } from '../config/AssetsMap';
import DefaultSelect from '../components/DefaultSelect';
import DefaultBtn from '../components/DefaultBtn';

const Container = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  padding: 0 20%;

  @media (max-width: 1200px) {
    padding: 0 3%;
    height: 80px;
  }
`;

const LogoImg = styled(Link)`
  background: url('${ASSETS_MAP.LOGO}') no-repeat;
  background-size: contain;
  width: 10%;
  height: 70%;

  @media (max-width: 1200px) {
    width: 25%;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  > select {
    margin: 0 15px;
  }

  @media (max-width: 1200px) {
    padding: 0 3%;
    height: 80px;

    > select {
      width: 100%;
    }
  }
`;

const Header = ({ setChapters, currentChapterName, currentHrefChapter, chapters, onChange }) => {
  const [selectedValue, setSelectedValue] = useState('');
  const history = useHistory();

  const [checkChapter, setCheckChapter] = useState([]);

  useEffect(() => {
    setSelectedValue(currentHrefChapter);
    if (chapters.map((e) => e.value).indexOf(currentHrefChapter) === -1) {
      const lista = [];

      chapters.map((a) => lista.push(a));
      if (currentHrefChapter) {
        lista.push({ label: currentChapterName || 'Adicionais', value: currentHrefChapter });
      }
      setCheckChapter(lista);
      setSelectedValue(currentHrefChapter);
    }
  }, [chapters, currentHrefChapter, currentChapterName, setChapters]);

  return (
    <Container>
      <LogoImg to='/' />
      <Row>
        <DefaultSelect
          currentChapterName={currentChapterName}
          onChange={({ target }) => {
            setSelectedValue(target?.value);
            onChange(target?.value);
          }}
          selectedValue={selectedValue}
          options={checkChapter}
        />
        <DefaultBtn label='Voltar' onClick={() => history.push('/')} />
      </Row>
    </Container>
  );
};

Header.propTypes = {
  chapters: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  currentHrefChapter: PropTypes.string.isRequired,
  currentChapterName: PropTypes.string.isRequired,
  setChapters: PropTypes.func.isRequired,
};

export default Header;
