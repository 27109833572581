import styled from 'styled-components';
import React from 'react';
import PropTypes from 'prop-types';

const SelectStyle = styled.select`
  outline: 0;
  border-radius: 21px;
  height: 42px;
  width: auto;
  font-weight: 600;
  background-color: #fff;
  color: #f05623;
  border: solid 2px #f05623;
  padding: 0 10px;
  cursor: pointer;
  max-width: 300px;

  :hover {
    text-decoration: underline;
  }
`;
const DefaultSelect = ({ options, onChange, selectedValue, currentChapterName }) => (
  <SelectStyle value={selectedValue} onChange={onChange}>
    {options.map((option) => (
      <option key={option?.value} value={option?.value}>
        {option?.label}
        {console.log(currentChapterName)}
      </option>
    ))}
  </SelectStyle>
);

DefaultSelect.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentChapterName: PropTypes.string.isRequired,
};

export default DefaultSelect;
