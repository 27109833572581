import React from 'react';
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import BookAvatar from "./BookAvatar";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const TextButton = styled.button`
  color: #FF7B69;
  align-self: flex-start;
  border: 0;
  background: transparent;
  font-family: DefaultFontBold, sans-serif;
`;

const ListContainer = styled.div`
  display: grid;
  flex: 1;
  overflow: hidden;
`;

const ListItem = styled(Link)`
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
`;

const Row = styled.div`
  display: flex;
  flex: 1;
  max-width: 100%;
  gap: 10px;
  overflow: auto;
`;

const Txt = styled.div`
  font-family: DefaultFont, sans-serif;
  color: #848690;
  text-align: center;
  font-size: 15px;
`;

const RecommendationList = ({ books }) => (
  <Container>
    <TextButton>
      Veja também
    </TextButton>
    <ListContainer>
      <Row>
        {books.map((item) => (
          <ListItem onClick={() => {
            window.scroll({ top: 0, behavior: 'smooth' })
          }} key={item.id} to={`/book/${item.id}`}>
            <BookAvatar cover={item.cover} active={!item.active} />
            <Txt>
              {item.name}
            </Txt>
          </ListItem>
        ))}
      </Row>
    </ListContainer>
  </Container>
);
RecommendationList.propTypes = {
  books: PropTypes.array.isRequired,
};

export default RecommendationList;