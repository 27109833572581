import React from 'react';
import styled from 'styled-components';
import PropTypes from "prop-types";

const Container = styled.div`
  box-shadow: 0 0 3px #0003;
  border-radius: 8px;
  padding: 15px;
  @media screen and (max-width: 450px) {
    display: none;
    padding: 10px;
  };
`;

const Txt = styled.span`
  color: #636383;
  font-family: DefaultFont, sans-serif;
  margin: 5px 0;
`;

const Highlight = styled.span`
  color: #F15623;
`;

const Title = styled.span`
  color: #636383;
  font-family: MontserratBold, sans-serif;
  font-size: 18px;
`;

const HighlightedText = styled.span`
  background: #F15623;
  color: #ffffff;
  font-family: DefaultFont, sans-serif;
  padding: 2.5px 10px;
  border-radius: 15px;
;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Hr = styled.hr`
  color: #D1D1DD;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const BookDetails = ({ category, volume, n, isbn, year }) => (
  <Container>
    <Row>
      <Title>
        Detalhes do livro
      </Title>
      <HighlightedText>
        {category}
      </HighlightedText>
    </Row>
    <Hr />
    <Col>
      <Txt>
        <Highlight>Volume:</Highlight> {volume}
      </Txt>
      <Txt>
        <Highlight>N° de Edição:</Highlight> {n}
      </Txt>
      <Txt>
        <Highlight>ISBN:</Highlight> {isbn}
      </Txt>
      <Txt>
        <Highlight>Ano:</Highlight> {year}
      </Txt>
    </Col>
  </Container>
);

BookDetails.propTypes = {
  category: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  n: PropTypes.number.isRequired,
  isbn: PropTypes.string.isRequired,
  volume: PropTypes.string.isRequired,
};

export default BookDetails;