import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';
import EpubReader from '../pages/EpubReader';
import Login from '../pages/Login';
import FirstAccess from '../pages/FirstAcess';
import RecoverPassword from '../pages/RecoverPassword';
import Library from '../pages/Library';
import Info from "../pages/Info";
import Redirect from "../pages/Redirect";

const MainRouter = () => (
  <Router>
    <Switch>
      <Route path='/login'>
        <Login />
      </Route>
      <Route path='/first-access'>
        <FirstAccess />
      </Route>
      <Route path='/recover-password'>
        <RecoverPassword />
      </Route>
      <Route path='/redirect/:token'>
        <Redirect />
      </Route>
      <PrivateRoute path='/reader/:bookId'>
        <EpubReader />
      </PrivateRoute>
      <PrivateRoute path='/book/:bookId'>
        <Info />
      </PrivateRoute>
      <PrivateRoute path='/'>
        <Library />
      </PrivateRoute>
    </Switch>
  </Router>
);

export default MainRouter;
