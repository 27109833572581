import React, { useCallback, useEffect, useState } from "react";
import styled from 'styled-components';
import { useHistory, useParams } from "react-router-dom";
import firebase from "firebase";
import BookCover from "../components/BookCover";
import BookResume from "../components/BookResume";
import BookDetails from "../components/BookDetails";
import RecommendationList from "../components/RecommendationList";
import { fbStore } from "../config/firebase";
import DefaultBtn from "../components/DefaultBtn";
import { ASSETS_MAP } from "../config/AssetsMap";

const Container = styled.div`
  //background: red;
  display: flex;
  justify-content: center;
  position: relative;
`;

const Grid = styled.div`
  flex: 1;
  //background: #0a53be;
  max-width: 1300px;
  max-height: 1200px;
  padding: 30px;
  display: grid;
  grid-gap: 0 80px;
  align-items: stretch;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr auto;
  grid-template-areas:
  'image resume'
  'info more'
  ;
  
  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
`;

const HeaderContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;

  @media (max-width: 1200px) {
    height: 80px;
  }
`;

const Col = styled.div`
  //background: greenyellow;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const LogoImg = styled.div`
  background: url('${ASSETS_MAP.LOGO}') center no-repeat;
  background-size: contain;
  width: 10rem;
  @media screen and (max-width: 450px) {
    width: 5rem;
  }
`;

const HeaderWrap = styled.div`
  max-width: 1300px;
  flex: 1;
  justify-content: space-between;
  display: flex;
  padding: 20px;
`;

const Info = () => {
  const { bookId } = useParams();
  const [book, setBook] = useState({});
  const [books, setBooks] = useState([]);
  const [fullAccess, setFullAccess] = useState(false);
  const history = useHistory();


  const verifyAccess = async (doc) => {
    const {currentUser} = firebase.auth();

    if (!currentUser || !currentUser.uid) {
        return false;
      }

      const access = await doc
      .ref
      .collection('access')
      .where('uid', '==', currentUser.uid)
      .get();

      if (access.docs.length === 0) {
        return false;
      }

    return access.docs[0].data().enabled;
};
    useEffect(() => {
    
    const dataFetch = async () => {

      const fullAccessRef =  fbStore.collection('config').doc('fullAccess');
      const docFlag = await fullAccessRef.get();
    
      if (!docFlag.exists) {
        console.log('Flag fullAccess Nao Existe');
      } else {

        setFullAccess(docFlag.data().fullAccess)
      }
    }

    dataFetch()


  }, [])
  
  const getBooks = useCallback(async () => {
    const allBooks = await fbStore.collection('books').where('enabled', '==', true).get();
    const promises = allBooks.docs.map(async (bookData) => {
      const active = fullAccess ? true : await verifyAccess(bookData);
      return {
        ...bookData.data(),
        id: bookData.id,
        active,
      }
    });

    const parsedBooks = await Promise.all(promises);
    const filter = parsedBooks.filter((currenBook) => currenBook.id !== bookId);
    setBooks(filter.sort((a, b) => a.n - b.n));

  }, [bookId, fullAccess]);

  useEffect(() => {
    getBooks().then();
  }, [getBooks]);

  useEffect(() => {
    if (!bookId) {
      return;
    }
    fbStore
      .collection('books')
      .doc(bookId)
      .get()
      .then(async (response) => {
        const active = fullAccess ? true : await verifyAccess(response);
        setBook({ ...response.data(), id: response.id, active });
      })
  }, [bookId, fullAccess]);

  return (
    <>
      <HeaderContainer>
        <HeaderWrap>
          <LogoImg src={ASSETS_MAP.LOGO} />
          <DefaultBtn label='Voltar' onClick={() => history.push('/')} />
        </HeaderWrap>
      </HeaderContainer>
      <Container id="book-preview">
        <Grid>
          <Col style={{ gridArea: 'image' }}>
            <BookCover cover={book.cover} id={book.id}  bookSummary={book.Summary}  active={fullAccess ? true : book.active} fullAccess={fullAccess} />
          </Col>
          <Col style={{ gridArea: 'resume' }}>
            <BookResume {...book} />
          </Col>
          <Col style={{ gridArea: 'info' }}>
            <BookDetails {...book} />
          </Col>
          <Col style={{ gridArea: 'more' }}>
            <RecommendationList books={books} />
          </Col>
        </Grid>
      </Container>
    </>
  );
}

export default Info;