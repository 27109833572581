import { fbAuth, fbFunctions } from '../config/firebase';

export const registerFirstAccess = async (email, password, confirmPassword, history) => {
  if (!password.trim() || !confirmPassword.trim()) {
    throw new Error('Preencha todos os campos !');
  }

  if (password !== confirmPassword) {
    throw new Error('Senhas não coincidem !');
  }

  const verifyUserRegistry = fbFunctions.httpsCallable('verifyUserRegistry');
  try {
    const response = await verifyUserRegistry({ email });

    if (response && response.data && response.data.status) {
      if (response.data.status === 'INATIVO') {
        alert('Assinatura Inativa !');
        return null;
      }

      if (response.data.status === 'ATIVO') {
        await fbAuth.createUserWithEmailAndPassword(email, password);
        setTimeout(() => {
          history.replace('/');
        }, 1000);
      }
    } else {
      alert('Usuário não encontrado');
    }
  } catch (e) {
    alert(e.message);
  }

  return null;
};
