import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { ASSETS_MAP } from '../config/AssetsMap';
import { fbAuth, fbStore } from '../config/firebase';
import DefaultBtn from '../components/DefaultBtn';

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  padding: 0 20%;

  @media (max-width: 1200px) {
    padding: 0 3%;
    height: 80px;
  }
`;

const BooksContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 40px;
  grid-auto-flow: row;
  justify-items: center;
  align-items: center;
  margin-top: 20px;
  padding: 0 20%;

  @media (max-width: 1366px) {
    padding: 0% 17%;
    grid-template-columns: repeat(3, auto);
  }
  @media (max-width: 360px) {
    padding: 0% 0% !important;
    grid-gap: 0px !important;
    grid-template-columns: repeat(3, auto);
  }
  @media (max-width: 1920px) {
    padding: 0% 19%;
    grid-template-columns: repeat(4, auto);
  }
  @media (max-width: 1536px) {
    padding: 0% 17%;
    grid-template-columns: repeat(3, auto);
  }
  @media (max-width: 1200px) {
    padding: 10px;
    grid-template-columns: repeat(4, auto);
  }
  @media (max-width: 1440px) {
    padding: 0% 18%;
    grid-template-columns: repeat(3, auto);
  }
  @media (max-width: 450px) {
    padding: 0 5%;
    grid-gap: 20px;
    grid-template-columns: repeat(2, auto) !important;
    grid-auto-rows: 1fr;
    align-items: baseline;
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(3, auto);
  }
`;

const BookTitle = styled.h1`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  @media screen and (max-width: 450px) {
    font-size: 12px;
    color: #848690;
  }
`;

const BookCaseContainer = styled(Link)`
  width: 200px;
  transition: 0.2s;
  font-weight: 600;
  font-size: 16pt;
  text-decoration: none;
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;

  :hover {
    transform: scale(1.05);
  }

  @media (max-width: 1200px) {
    width: 170px;
    font-size: 16pt;
  }

  @media (max-width: 768px) {
    width: 90px;
    font-size: 10pt;
  }
  @media (max-width: 1920px) {
    width: 230px;
    font-size: 23pt;
  }

  @media screen and (max-width: 450px) {
    width: 100%;
  }
`;

const BookCase = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1);
  border: 1px solid #ccc;
  cursor: pointer;
  transition: 0.2s;
  background: url('${({ book }) => book && book.cover}') no-repeat;
  background-size: cover;
  border-radius: 8px;

  :hover {
    box-shadow: inset 10px 0 20px rgba(0, 0, 0, 0.1), 0 5px 20px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 1200px) {
    height: 250px;
  }

  @media (max-width: 768px) {
    height: 18.6rem;
  }
  
  @media screen and (max-width: 450px) {
    height: 200px;
  }
`;

const LogoImg = styled.div`
  background: url('${ASSETS_MAP.LOGO}') no-repeat;
  background-size: contain;
  width: 10rem;
  height: 70%;
`;

const Library = () => {
  const [books, setBooks] = useState([]);

  const getBooks = useCallback(async () => {
    const allBooks = await fbStore.collection('books').where('enabled', '==', true).orderBy('ordem').get();
    const parsedBooks = allBooks.docs.map((book) => ({ ...book.data(), id: book.id }));
    setBooks(parsedBooks);
  }, []);

  useEffect(() => {
    getBooks().then();
  }, [getBooks]);

  return (
    <Container>
      <HeaderContainer>
        <LogoImg />
        <DefaultBtn label='Desconectar' onClick={() => fbAuth.signOut()} />
      </HeaderContainer>
      <BooksContainer>
        {books.map((book) => (
          <BookCaseContainer key={book.id} to={`/book/${book.id}`}>
            <BookCase book={book} />
            <BookTitle>{book.name || book.title}</BookTitle>
          </BookCaseContainer>
        ))}
      </BooksContainer>
    </Container>
  );
};

export default Library;
