import React from 'react';
import PropTypes from "prop-types";
import styled from 'styled-components';
import close from '../assets/close.svg';
import DefaultBtn from "./DefaultBtn";

const Container  = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  @media screen and (max-width: 450px) {
    height: 200%;
  }
`;

const Overlay = styled.div`
  background: #0006;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Card = styled.div`
  width: 50%;
  height: 250px;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`;

const SimpleButton = styled.button`
  background: transparent;
  border: none;
  align-self: flex-end;
`;

const Txt = styled.span`
  font-family: MontserratBold, sans-serif;
  color: #848690;
  text-align: center;
  font-size: 15px;
`;

const Highlight = styled.span`
  color: #FF7B69;
`;

const AccessModal = ({ closeRequest, visible }) => (
  <Container visible={visible}>
    <Overlay>
      <Card>
        <SimpleButton onClick={closeRequest}>
          <img src={close} alt="" />
        </SimpleButton>
        <Txt>
          Para ter acesso completo ao conteúdo deste livro, entre em contato: <Highlight>contato@magiadeler.com.br</Highlight>
        </Txt>
        <DefaultBtn label="Voltar" onClick={closeRequest} />
      </Card>
    </Overlay>
  </Container>
);

AccessModal.propTypes = {
  closeRequest: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

export default AccessModal;