/* eslint no-throw-literal: "error" */
import epub from "epubjs";
import { useParams, useHistory, useLocation } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { makeStyles } from "@material-ui/core/styles";
import { faChevronCircleDown, faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { useSwipeable } from "react-swipeable";
import $ from "jquery";
import Tooltip from "@material-ui/core/Tooltip";
import SyncLoader from "react-spinners/SyncLoader";
import firebase from 'firebase';
import Header from "../containers/Header";
import { fbStore } from "../config/firebase";
import ScrollToTop from "../components/ScrollToTop";



const useStyles = makeStyles({
  tooltip: {
    fontSize: "0.8em",
  },
});

const Container = styled.div`
  width: 100%;
  height: 100vh;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: 360px) {
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
`;

const ReaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1;
  position: relative;
  overflow: hidden;

  @media (max-width: 360px) {
    width: 80%;
    margin: 0 auto;
    padding: 0;
  }
`;

const Viewer = styled.div`
  height: max-content !important;
`;

const Actions = styled.div`
  padding: 2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.2s;
  opacity: 0.2;
  position: absolute;

  :hover {
    opacity: 1;
  }

  @media (max-width: 1200px) {
    opacity: 0;

    :hover {
      opacity: 0;
    }
  }
`;

const BookViewer = styled.div`
  position: relative;
  overflow: auto;
  width: 100%;
  flex: 1;
  align-self: stretch;
  display: flex;
  justify-content: center;
`;

const TooltipWrap = styled.div`
  position: absolute;
  bottom: 0;
  @media screen and (max-width: 450px) {
    display: none;
  }
`;

function useQuery() {
  const { preview } = useLocation();

  return React.useMemo(() => new URLSearchParams(preview), [preview]);
}

const EpubReader = () => {
  const { bookId } = useParams();
  const { search } = useLocation();

  const query = new URLSearchParams(search);
  const preview = query.get('preview');
  const readerRef = useRef(null);
  const history = useHistory();
  const divReaderRef = useRef(null);


  const [chapters, setChapters] = useState([]);
  const [bookLink, setBookLink] = useState('');
  const [containerHeight, setContainerHeight] = useState(0);
  const [fullAccess, setFullAccess] = useState(false);
  const [chapterContentHeight, setChapterContentHeight] = useState(0);
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);


  const [currentHrefChapter, setCurrentHrefChapter] = useState('');
  const [currentChapterName, setCurrentChapterName] = useState('');

  const [loading, setLoading] = useState(true);

  const classes = useStyles();

  const prevScroll = useRef(0);
  const viewerRef = useRef(null);

  useEffect(() => {
    
    const dataFetch = async () => {

      const fullAccessRef =  fbStore.collection('config').doc('fullAccess');
      const docFlag = await fullAccessRef.get();
    
      if (!docFlag.exists) {
        console.log('Flag fullAccess Nao Existe');
      } else {

        setFullAccess(docFlag.data().fullAccess)
      }
    }

    dataFetch()


  }, [])

  const verifyAccess = async (doc) => {
    const {currentUser} = firebase.auth();

    if (!currentUser || !currentUser.uid) {
      return false;
    }

      const access = await doc
      .ref
      .collection('access')
      .where('uid', '==', currentUser.uid)
      .get();

    if (access.docs.length === 0) {
      return false;
    }

    return access.docs[0].data().enabled;

  };

  const searchForBook = useCallback(async () => {
    const bookRef = await fbStore.collection('books').doc(bookId).get();
    if (preview) {
      setBookLink(bookRef.data() && bookRef.data().bookSummary);
      return;
    }
    
    const asAccess = fullAccess ? true : await verifyAccess(bookRef);
    if (asAccess) {
      setBookLink(bookRef.data() && bookRef.data().book)
    } else {
    //   history.goBack();
    }
  }, [bookId,  preview, fullAccess]);

  useEffect(() => {
    searchForBook().then();
  }, [searchForBook]);

  const getScreenResolution = () => {
    const container = document.getElementById('scroll-container');

    if (container) {
      // console.log('container', container.offsetHeight);
      setContainerHeight(container.offsetHeight);
    }

    const viewerDiv = document.getElementById('viewer');

    if (viewerDiv) {
      // console.log('viewerDiv', viewerDiv.offsetHeight);
      setTimeout(() => {
        setChapterContentHeight(viewerRef.current.offsetHeight);
      }, 500)
    }
  };

  const getBestResolutionMatch = ({ clientWidth, clientHeight }) => {

    if (clientWidth <= 1024) {
      return {
        width: clientWidth * 1,
        height: clientHeight * 1
      };
    }

    if (clientWidth <= 1200) {
      return {
        width: clientWidth * 0.85,
        height: clientHeight * 0.9
      };
    }

    return {
      width: clientWidth * 0.6 > 1000 ? 1000 : clientWidth * 0.6,
      height: clientHeight * 1,
    };
  };

  useEffect(() => {
    if (bookLink && bookId) {
      const book = epub(bookLink);
      readerRef.current = book.renderTo('viewer', {
        flow: "scrolled-doc",
        method: "default",
        width: getBestResolutionMatch(divReaderRef.current).width,
        snap: true,
      });

      readerRef.current.display(localStorage.getItem(bookId) || undefined);

      book.loaded.navigation.then(({ toc }) => {
        const allChapters = toc.map((chapter) => ({
          label: chapter.label,
          value: chapter.href,
        }));
        setChapters([]);
        setChapters(allChapters);
      });

      readerRef.current.on('relocated', (location) => {
        localStorage.setItem(bookId, location.start.cfi);
      });

      readerRef.current.on('rendered', (section) => {
        const hrefChapter = section?.href;
        const chapterName = section?.document?.all[11]?.outerText;
        setCurrentHrefChapter(hrefChapter);
        setCurrentChapterName(chapterName?.toString());
        setLoading(false);
        getScreenResolution();
      });

      return () => book.destroy();
    }

    return () => {};
  }, [bookId, bookLink]);

  const advance = () => {
    if (
      readerRef &&
      readerRef.current &&
      readerRef.current.next &&
      typeof readerRef.current.next === 'function'
    ) {
      readerRef.current.next();
      getScreenResolution();
    }
  };

  const goBack = () => {
    if (
      readerRef &&
      readerRef.current &&
      readerRef.current.prev &&
      typeof readerRef.current.prev === 'function'
    ) {
      readerRef.current.prev();
      readerRef.current.prev();
      advance();
      getScreenResolution();
    }
  };

  const handlers = useSwipeable({
    onSwiped: (eventData) => console.log('User Swiped!', eventData),
  });

  useEffect(() => {
    $('#viewer').ready(() => {
      $('iframe').attr("sandbox","allow-scripts").on('load', () => {
        $('div').contents().find('img').css('max-height', 'none');
      });
    });
  }, []);

  const handleScroll = async (e) => {
    const { scrollTop } = e.target;

    const calc = (chapterContentHeight - scrollTop) - containerHeight;

    if (chapterContentHeight < (2 * containerHeight)) {
      if (scrollTop === 0 && calc < containerHeight) {
        setShowScrollIndicator(true);
      }
      if (calc <= 0) {
        setShowScrollIndicator(false);
      }
    }
    if (prevScroll.current > containerHeight && containerHeight > calc) {
      setShowScrollIndicator(false);
    }

    if (prevScroll.current < containerHeight && calc > containerHeight) {
      setShowScrollIndicator(true);
    }

    prevScroll.current = calc
  };


  const scrollDown = () => {
    const scrollContainer = document.getElementById('scroll-container');

    if (scrollContainer) {
      const { scrollTop } = scrollContainer;

      scrollContainer.scrollTop = scrollTop + (containerHeight * 0.90);
    }
  };

  const display = (url) => {
    try {
      if (readerRef && readerRef.current) {
        readerRef.current.display(url);
      }
    } catch (e) {
      console.log('erro de display', e);
    }
  }

  return (
    <Container>
      <Header
        setChapters={setChapters}
        currentChapterName={currentChapterName}
        currentHrefChapter={currentHrefChapter}
        chapters={chapters}
        onChange={(url) => display(url)}
      />
      <ScrollToTop />
      <ReaderContainer {...handlers} ref={divReaderRef}>
        <div style={{ position: 'absolute' }}>
          <SyncLoader size={20} loading={loading} color='#f05623' />
        </div>
        <div style={{ width: '100%', display: 'flex', alignSelf: 'stretch' }} id='book-viewer'>
          <BookViewer
            id="scroll-container"
            onScroll={(e) => handleScroll(e)}
          >
            <Viewer ref={viewerRef} id="viewer" style={{ height: 'maxContent' }} />
          </BookViewer>

        
        </div>
        {showScrollIndicator &&(
          <TooltipWrap id="wrap">
            <Tooltip classes={{tooltip: classes.tooltip}} arrow title='Você pode deslizar para baixo para visualizar mais conteúdos'>
              <Actions style={{ bottom: 0 }}>
                <FontAwesomeIcon onClick={() => scrollDown()} color='#2681cc' size='3x' icon={faChevronCircleDown} />
              </Actions>
            </Tooltip>
          </TooltipWrap>
        )}
        <Tooltip title='Página anterior'>
          <Actions style={{ left: 0 }} onClick={goBack}>
            <FontAwesomeIcon color='#f05623' size='3x' icon={faChevronCircleLeft} />
          </Actions>
        </Tooltip>
        <Tooltip title='Página seguinte'>
          <Actions style={{ right: 0 }} onClick={advance}>
            <FontAwesomeIcon color='#f05623' size='3x' icon={faChevronCircleRight} />
          </Actions>
        </Tooltip>
      </ReaderContainer>
    </Container>
  );
};

export default EpubReader;
