import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/functions';

// Initialize Firebase
const firebaseConfig = {
  apiKey: 'AIzaSyCziLtjn20kjHDKwhneC9oovwuRIr6ckAs',
  authDomain: 'jornaljoca-epub-reader.firebaseapp.com',
  projectId: 'jornaljoca-epub-reader',
  storageBucket: 'jornaljoca-epub-reader.appspot.com',
  messagingSenderId: '1085169197185',
  appId: '1:1085169197185:web:cf0b0c11a7ca211ed02d80',
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export const fbAuth = firebase.auth();
export const fbStore = firebase.firestore();
export const fbFunctions = firebase.functions();

fbFunctions.useEmulator('localhost', 5001);
