import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory } from 'react-router-dom';
import DefaultInput from '../components/DefaultInput';
import DefaultBtn from '../components/DefaultBtn';
import LoginBox from '../components/LoginBox';
import DefaultTitle from '../components/DefaultTitle';
import { registerFirstAccess } from '../functions/users';

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BottomLinks = styled(Link)`
  display: block;
  color: #333;
  font-weight: 600;
  text-align: center;
  margin-top: 8px;
  text-decoration: none;

  :hover {
    text-decoration: underline #f05623;
  }
`;

const FirstAccess = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const history = useHistory();

  return (
    <Container>
      <DefaultTitle>
        Primeiro acesso a biblioteca do jornal <strong>Joca</strong>
      </DefaultTitle>
      <LoginBox>
        <DefaultInput
          value={email}
          type='email'
          label='E-Mail (Mesmo utilizado no Jornal Joca)'
          onChange={(event) => setEmail(event.target.value)}
        />
        <DefaultInput
          value={password}
          label='Senha'
          type='password'
          onChange={(event) => setPassword(event.target.value)}
        />
        <DefaultInput
          value={confirmPassword}
          label='Confirme sua Senha'
          type='password'
          onChange={(event) => setConfirmPassword(event.target.value)}
        />
        <DefaultBtn
          onClick={() => registerFirstAccess(email, password, confirmPassword, history)}
          label='Registar Acesso'
        />
        <BottomLinks to='/login'>Já é registado ?</BottomLinks>
        <BottomLinks to='/recover-password'>Esqueceu sua senha?</BottomLinks>
      </LoginBox>
    </Container>
  );
};

export default FirstAccess;
