import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import Arrow from '../assets/arrow.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: 600px;
  @media screen and (max-width: 450px) {
    max-height: unset;
    padding: 10px;
  };
`;

const Title = styled.h2`
  font-family: "DefaultFontBold", sans-serif;
  color: #4B4A68;
  font-size: 38px;
  @media screen and (max-width: 450px) {
    font-size: 25px;
  }
;
`

const SubTitle = styled.h4`
  font-size: 20px;
  color: #FF7B69;
  font-family: "DefaultFontBold", sans-serif;
`;

const Txt = styled.div`
  font-family: "DefaultFont", sans-serif;
  color: #848690;
  flex: 1;
  white-space: pre-line;
  @media screen and(max-width: 450px) {
    font-size: 12px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

const Hr = styled.hr`
  color: #D1D1DD;
`;

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Highlight = styled.span`
  color: #FF7B69;
`;

const HighlightedText = styled.span`
  background: #FF7B69;
  color: #ffffff;
  font-family: DefaultFont, sans-serif;
  padding: 2.5px 10px;
  border-radius: 15px;
  align-self: flex-start;
;
`;

const Author = styled.p`
  font-family: "DefaultFontBold", sans-serif;
  color: #848690;
`;

const Details = styled.div`
  display: none;
  @media screen and (max-width: 450px) {
    display: flex;
    flex-direction: column;
  };
`;

const BookResume = ({ category, volume, isbn, n, year, name, summary, authors }) => (
  <Container>
    <Hr />
    <Title>
      {name}
    </Title>
    <Author>
      <Highlight>Autores: </Highlight>
       {authors && Array.isArray(authors) && authors.map((author, index) => `${String(author)}${authors.length === index + 1 ? '' : ','} `)}
    </Author>
    <Details>
      <Title style={{ fontSize: 18 }}>
        Detalhes do livro
      </Title>
      <Hr />
      <Txt>
        <Highlight>Volume:</Highlight> {volume}
      </Txt>
      <Txt>
        <Highlight>N° de Edição:</Highlight> {n}
      </Txt>
      <Txt>
        <Highlight>ISBN:</Highlight> {isbn}
      </Txt>
      <Txt>
        <Highlight>Ano:</Highlight> {year}
      </Txt>
      <HighlightedText>
        {category}
      </HighlightedText>
    </Details>
    <Hr />
    <SubTitle>
      Sinopse
    </SubTitle>
    <Content>
      <Txt style={{ whiteSpace: 'pre-wrap' }}>
        {summary}
      </Txt>
    </Content>
    <Hr />
    {/* <Row>
      <img src={Arrow} alt="" />
    </Row> */}
  </Container>
);

BookResume.propTypes = {
  summary: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  n: PropTypes.number.isRequired,
  isbn: PropTypes.string.isRequired,
  volume: PropTypes.string.isRequired,
  authors: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
};

export default BookResume;